import { AuthService } from 'src/app/core/authentication/authentication.service';
import { CustomEncoder, prependPay } from 'src/app/service.portal.http.config';
import { PayResponse } from 'src/app/service.portal.types';
import { v4 as uuid } from 'uuid';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { datadogLogs } from '@datadog/browser-logs';
import { BehaviourService, THEME_NAME } from '@pa/lib-spa';
import { PaymentOption } from '@pa/references';
import { ContactType } from '@pa/references/idf';

import { InsuranceService } from '../insurance.service';

@Component({
    selector: 'sp-payments',
    templateUrl: './payments.page.html',
})
export class PaymentsPage implements OnInit {
    @ViewChild('errorDialog', { static: true }) errorDialog: TemplateRef<any> | undefined;
    public supportEmail: string;
    public submitting: boolean;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _dialog: MatDialog,
        private _router: Router,
        private ngZone: NgZone,
        private _http: HttpClient,
        private _customEncoder: CustomEncoder,
        private _authService: AuthService,
        private _insuranceService: InsuranceService,
        private behaviourService: BehaviourService
    ) {}

    ngOnInit() {
        this._activatedRoute.queryParams.subscribe((params) => {
            const { stripeSession, status } = params;
            if (stripeSession) {
                this._handleStripeRedirect(stripeSession, status);
            }
        });
        this.behaviourService.behaviour.subscribe((behaviour) => {
            this.supportEmail = behaviour.uiConfig.contacts.find(
                (c) => c.type === ContactType.genericSupport
            )?.emailAddress;
        });
    }

    private async _handleStripeRedirect(sessionId: string, status: string) {
        if (status === 'paid') {
            const { token, code } = this._authService.session;

            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                params: new HttpParams({
                    encoder: this._customEncoder,
                    fromObject: { token, code, sessionId },
                }),
            };

            this.submitting = true;
            this._http.get<PayResponse<any>>(prependPay('/v1/payments/stripe/checkout'), options).subscribe({
                next: (res) => {
                    const { effectiveDate, status, reference } = res.result;
                    if (status === 'paid') {
                        this._acceptTerms({ effectiveDate, option: PaymentOption.stripe, reference });
                    } else {
                        this._showError();
                    }
                },
                error: (res) => {
                    this._showError();
                },
            });
        } else {
            this._router.navigate(['/quote']);
        }
    }

    private _acceptTerms(payment: { effectiveDate?: string; option: PaymentOption; reference: string }) {
        const { behaviour, token, commission, commissionTaxRate, invoiceId, code, originatorCode } =
            this._authService.session;

        const reqData = {
            behaviour,
            code,
            commission,
            commissionTaxRate,
            invoiceId,
            paidDate: payment.effectiveDate ?? new Date().toISOString(),
            paymentOption: payment.option,
            paymentReference: payment.reference,
            reference: uuid(),
            token,
            originatorCode,
        };

        this.submitting = true;
        datadogLogs.logger.info(`accepting quote, ${JSON.stringify(reqData)}`);
        this._insuranceService.submitTermsAcceptance(invoiceId, reqData).subscribe({
            next: (res) => {
                datadogLogs.logger.info(`accepted quote, ${JSON.stringify(reqData)}`);
                this._router.navigate(['/summary']);
            },
            error: (err) => {
                console.error(err);
                datadogLogs.logger.error(`accept quote error : ${err.message}, ${JSON.stringify(reqData)}`);
                this._showError();
            },
        });
    }

    private _showError() {
        this.submitting = false;
        if (this.errorDialog) {
            //ngZone
            this.ngZone.run(() => {
                this._dialog.open(this.errorDialog, {
                    panelClass: THEME_NAME,
                });
            });
        }
    }
}
