import { Component, OnInit } from '@angular/core';
import moment from 'moment-timezone';
import { forkJoin, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { BehaviourService, ThemeService, getFileTemplateType } from '@pa/lib-spa';

import { AuthService } from '../../core/authentication/authentication.service';
import { IncidentReportServiceDetails, TemplateType, Theme } from '../../service.portal.types';
import { InsuranceService, listPoints } from '../insurance.service';
import { capitalize } from 'lodash';
import { FormatDateConfig } from '@pa/lib-spa/types';

@Component({
    selector: 'sp-incident-report',
    templateUrl: './incident-report.page.html',
    styleUrls: ['./incident-report.page.scss'],
})
export class IncidentReportPage implements OnInit {
    public documents: { display: string; url: string }[] = [];
    public attachments: { display: string; url: string }[] = [];
    public dateOfLoss: FormatDateConfig;
    public clientPolicy: { [key: string]: FormatDateConfig | string } = {};
    public listClass: string;
    public loading = true;
    public paClient: IncidentReportServiceDetails['paClient'];
    public theme: Observable<Theme>;

    constructor(
        private _authService: AuthService,
        private _behaviourService: BehaviourService,
        private _themeService: ThemeService,
        private _insuranceService: InsuranceService
    ) {
        this.theme = _themeService.theme;
        this.theme.subscribe((theme) => {
            this.listClass = listPoints[theme.slug];
        });
    }

    ngOnInit() {
        const info = this._authService.getInsuranceInfo();

        if (info.incidentReport) {
            forkJoin([
                this._behaviourService.behaviour.pipe(first()),
                this._insuranceService.getIncidentReport(info.incidentReport, info.token),
            ]).subscribe({
                next: ([b, ir]) => {
                    ir.documentationLinks.forEach((d) => {
                        const templateType = getFileTemplateType(d.filename, b);

                        switch (templateType) {
                            case TemplateType.incidentReport: {
                                this.documents.push({ display: 'Incident Report', url: d.url });
                                return;
                            }
                            default: {
                                const index = d.filename.indexOf(`_${info.incidentReport}`);
                                const display = capitalize(
                                    d.filename.substring(0, index === -1 ? d.filename.length : index)
                                );
                                this.attachments.push({ display, url: d.url });
                                return;
                            }
                        }
                    });

                    this.clientPolicy = {
                        expiryDate: {
                            date: moment.utc(ir.clientPolicy.expiryDate).subtract(1, 'd').toISOString(),
                            timezone: info.timezone,
                        },
                        reference: ir.clientPolicy.reference,
                        renewalDate: { date: ir.clientPolicy.renewalDate, timezone: info.timezone },
                    };
                    this.dateOfLoss = { date: ir.dateOfLoss, timezone: info.timezone };
                    this.paClient = ir.paClient;

                    this.loading = false;
                },
                error: (err) => console.error(err),
            });
        }
    }
}
